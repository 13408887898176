<template>
  <div id="merchantTab-vehicleListTab">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addVehicle"
              type="primary"
              @click="$refs.actionDialog.show = true"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-merchantTab-detail-vehicleList_Tab-add'
                )
              "
              ><i class="iconfont icon-jiahao"></i> 新建车辆
            </el-button>
          </li>
          <li
            v-if="
              buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-vehicleList_Tab-import'
              )
            "
          >
            <file-upload
              url="/freeCar/import"
              title="导入挂单车"
              :data="{
                parkingLotId: $route.query.parkingLotId,
                merchantId: $route.params.id,
              }"
              @success="onImportSuccess"
            />
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column label="车牌号" align="center">
          <template slot-scope="scope">
            <span
              style="cursor: pointer"
              @click="goToFreeOrder(scope.row.plateNum)"
              >{{ scope.row.plateNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="releaseAmount" label="发放金额" align="center">
        </el-table-column>
        <el-table-column prop="availableAmount" label="可用金额" align="center">
        </el-table-column>
        <el-table-column prop="releaseType" label="发放类型" align="center">
        </el-table-column>
        <el-table-column prop="contactName" label="联系人" align="center">
        </el-table-column>
        <el-table-column prop="contactPhone" label="联系方式" align="center">
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-merchantTab-detail-vehicleList_Tab-edit'
                )
              "
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="del(scope.row.carId)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-merchantTab-detail-vehicleList_Tab-delete'
                )
              "
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, prev, pager, next,jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @size-change="getList(1)"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
    <action-dialog ref="actionDialog" @refreshList="getList" />
  </div>
</template>
<script>
export default {
  components: {
    actionDialog: () => import("./actionDialog.vue"),
    fileUpload: () => import("@/components/fileUpload.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    console.log(this.$route.query.parkingLotId);
    this.getList();
  },
  computed: {
    tabList() {
      return this.$store.state.controlAuthority.tabList;
    },
  },
  inject: ["changeTab"],
  methods: {
    goToFreeOrder(plateNum) {
      if (
        this.tabList.includes(
          "parkingLotList-detail-merchantTab-detail-freeCarOrderList_Tab"
        )
      ) {
        this.changeTab(
          "parkingLotList-detail-merchantTab-detail-freeCarOrderList_Tab",
          { keyword: plateNum }
        );
      }
    },
    del(carId) {
      this.$confirm("此操作将永久删除该车辆, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(`/freeCar/delete/${carId}`);
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    edit(row) {
      this.$refs.actionDialog.carId = row.carId;
      this.$refs.actionDialog.actionForm = {
        plateNum: row.plateNum,
        releaseAmount: row.releaseAmount,
        availableAmount: row.availableAmount,
        releaseType: row.releaseType,
        contactName: row.contactName,
        contactPhone: row.contactPhone,
        remark: row.remark,
      };
      this.$refs.actionDialog.show = true;
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    onImportSuccess() {
      this.$message.success("导入成功");
      this.getList();
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/freeCar/list", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
          merchantId: this.$route.params.id,
        });
        if (res.code === 0) {
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#merchantTab-vehicleListTab {
  .table-container {
    .table-filtrate {
      ul {
        .taigang {
          font-size: 12px;
          color: hsla(26, 100%, 64%, 0.96);

          span {
            color: hsla(217, 48%, 15%, 0.96);
            margin-right: 4px;
          }
        }

        li {
          .addVehicle {
            background-color: #ff9747;
            border-color: #ff9747;

            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
